<template>
  <div>
    <MainStepper e1="5" />
    <v-card elevation="2" :loading="loading" class="mx-auto m-1">
      <template slot="progress">
        <v-progress-linear
          color="primary"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title class="primary--text">
        <v-col cols="6" class="my-n2">
          <span class="text-h6 primary--text"> CONTRACT CREATION </span>
        </v-col>
        <v-col cols="6" class="my-n2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="goToSupplierSelection"
                elevation="0"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>Continue</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="goToSupplierSelection"
                elevation="0"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>Back</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="gotToMyApprovals"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                fab
                x-small
              >
                <v-icon>mdi-clipboard-check-outline</v-icon>
              </v-btn>
            </template>
            <span>My contracts</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="gotToMyApprovals"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                fab
                x-small
              >
                <v-icon>mdi-text-box-remove-outline</v-icon>
              </v-btn>
            </template>
            <span>Expired contract</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize ml-1 float-right"
                :loading="loading"
                @click="gotToAllContracts"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                fab
                x-small
              >
                <v-icon>mdi-file-sign</v-icon>
              </v-btn>
            </template>
            <span>All contracts</span>
          </v-tooltip>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-divider class="my-n1"></v-divider>
        <div class="justify-left ml-5 my-auto">
          BETWEEN:
          <v-chip class="mx-5 mt-" color="success" label outlined>
            {{ user.company.company_name }}
          </v-chip>
          <bold class="mt-">&</bold>
          <v-chip
            v-if="supplier.company"
            class="mx-5 my-4"
            color="success"
            label
            outlined
          >
            {{ supplier.company.company_name }}
          </v-chip>
          <v-chip
            v-else
            class="mx-5 my-4"
            color="error"
            label
            outlined
            @click="goToSupplierSelection"
          >
            Please select a supplier to continue *
          </v-chip>
        </div>
        <v-divider></v-divider>

        <v-stepper
          v-if="supplier.company"
          v-model="e6"
          vertical
          buffer-value="100"
          width="8"
          striped
        >
          <v-stepper-step :complete="e6 > 1" step="1" color="success">
            Initiation
            <small> Get started</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-form
              ref="form_contract_initiation"
              v-model="valid"
              lazy-validation
            >
              <div id="contract-initiation">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.title"
                      label="Name"
                      :value="requisition.title"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.reference"
                      label="Reference *"
                      outlined
                      dense
                      disabled
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.contract_option"
                      :items="contract_options"
                      label="Select Initiate type *"
                      item-text="name"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.contract_type_id"
                      :items="contract_types"
                      label="Select type *"
                      item-text="title"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="4">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormatted"
                          label="Effective To *"
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          v-bind="attrs"
                          @blur="date = parseDate(dateFormatted)"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="reminder_types"
                      label="(Reminder Type)"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      class=""
                      v-model="editedItem.description"
                      label="Provide description for this contract..."
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <v-btn
                class="success text-capitalize ml-1 float-right"
                @click="saveStepOne"
              >
                Save & continue
                <v-icon>mdi-arrow-down-circle-outline</v-icon></v-btn
              >
            </v-form>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2" color="success">
            Drafting & Negotiation
            <small
              >Collaborate & Draft a contract document, document currently on
              the negotiating table and undergoing amendments</small
            >
          </v-stepper-step>

          <v-stepper-content step="2">
            <!-- Start drafting  -->

            <div id="draft_page">
              <v-row>
                <v-col cols="12">
                  <v-card class="mx-auto" outlined>
                    <v-card-title caption class="primary--text">
                      Contract Drafting
                      <v-spacer></v-spacer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="info"
                            dark
                            class="text-capitalize ml-1"
                            :loading="loading"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-comment-flash-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Negotiate</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1"
                            :loading="loading"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-autocomplete
                              v-model="editedItem.content"
                              :items="contract_templates"
                              label="Template"
                              item-text="title"
                              item-value="id"
                              color="grey"
                              dense
                              class="w-64 mb-n2 white--text"
                              @change="templateSelected"
                              :rules="selectRules"
                            ></v-autocomplete>

                            <v-icon small>mdi-file-replace-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Templates</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Revisions
                            <v-icon small>mdi-file-edit-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Revisions</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-file-undo-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Clear pad and its contents</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-text-box-check-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Save Draft Revision</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-account-multiple-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Invite Guest Collaborators</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-file-export-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Export Draft</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-delete-forever-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete pad and its contents</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            @click="goToNeedIdentification"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-reload</v-icon>
                          </v-btn>
                        </template>
                        <span>Hot reload</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="text-capitalize ml-1 float-righ"
                            :loading="loading"
                            elevation="0"
                            v-bind="attrs"
                            v-on="on"
                            fab
                            x-small
                          >
                            <v-icon>mdi-clipboard-check-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>All contracts</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <!-- Use the component in the right place of the template -->
                      <v-card>
                        <editor
                          v-model="editedItem.content"
                          api-key="8g0uc9aqmyh9suvkt1n7m29cb3qc0686c3he2wnwmpp1smre"
                          :init="{
                            height: 900,
                            menubar: true,
                            autosave_ask_before_unload: false,
                            powerpaste_allow_local_images: true,
                            plugins: [
                              'advlist anchor autolink codesample fullscreen help image imagetools tinydrive',
                              ' lists link media noneditable preview',
                              ' searchreplace table template visualblocks wordcount',
                            ],
                            toolbar:
                              'insertfile undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image tinydrive',
                            tinydrive_demo_files_url:
                              '/docs/demo/tiny-drive-demo/demo_files.json',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }"
                        />
                      </v-card>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions> </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <!-- End drafting  -->
            <v-btn class="success text-capitalize" @click="e6 = 1">
              <v-icon>mdi-arrow-up-circle-outline</v-icon> Previous
            </v-btn>
            <v-btn
              class="success text-capitalize ml-1 float-right"
              :loading="loading"
              @click="saveStepTwo"
            >
              Save & Continue
              <v-icon>mdi-arrow-down-circle-outline</v-icon></v-btn
            >
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3" color="success">
            Publication & Execution
            <small
              >Contracts already published waiting for signing and
              execution</small
            >
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-divider class=""></v-divider>

            <v-card>
              <editor
                v-model="editedItem.content"
                api-key="8g0uc9aqmyh9suvkt1n7m29cb3qc0686c3he2wnwmpp1smre"
                :init="{
                  height: 900,
                  menubar: true,
                  autosave_ask_before_unload: false,
                  powerpaste_allow_local_images: true,
                  plugins: [
                    'advlist anchor autolink codesample fullscreen help image imagetools tinydrive',
                    ' lists link media noneditable preview',
                    ' searchreplace table template visualblocks wordcount',
                  ],
                  toolbar:
                    'insertfile undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image tinydrive',
                  tinydrive_demo_files_url:
                    '/docs/demo/tiny-drive-demo/demo_files.json',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }"
              />
            </v-card>

            <v-row>
              <v-card
                max-width="310px"
                max-height="250px"
                class="mx-5 my-3"
                elevation="2"
              >
                <h2 class="mx-1 primary--text">{{ user.company.company_name }}</h2>
                <a class="mx-1" caption> Signatory </a>

                <vueSignature
                  ref="signature"
                  :sigOption="option"
                  :w="'300px'"
                  :h="'110px'"
                  :disabled="disabled"
                ></vueSignature>

                <v-btn
                  class="primary text-capitalize ma-1"
                  @click="save"
                  elevation="0"
                >
                  Save <v-icon>mdi-check-circle-outline</v-icon></v-btn
                >

                <v-btn
                  class="primary text-capitalize ma-1"
                  @click="clear"
                  elevation="0"
                >
                  Clear <v-icon>mdi-autorenew</v-icon></v-btn
                >

                <v-btn
                  class="primary text-capitalize ma-1"
                  @click="undo"
                  elevation="0"
                >
                  Undo <v-icon>mdi-arrow-u-left-top</v-icon></v-btn
                >
              </v-card>

              <v-spacer></v-spacer>

              <v-card
                max-width="310px"
                max-height="250px"
                class="ma-2"
                elevation="2"
              >
                <h2 class="mx-1 primary--text">{{ supplier.company.company_name }}</h2>
                <a class="mx-1" caption> Signatory </a>
                <vueSignature
                  ref="signature_supplier"
                  :sigOption="option"
                  :w="'300px'"
                  :h="'110px'"
                  :disabled="disabled"
                ></vueSignature>

                <v-btn
                  class="primary text-capitalize ma-1"
                  @click="save"
                  elevation="0"
                >
                  Save <v-icon>mdi-check-circle-outline</v-icon></v-btn
                >

                <v-btn
                  class="primary text-capitalize ma-1"
                  @click="clear"
                  elevation="0"
                >
                  Clear <v-icon>mdi-autorenew</v-icon></v-btn
                >

                <v-btn
                  class="primary text-capitalize ma-1"
                  @click="undo"
                  elevation="0"
                >
                  Undo <v-icon>mdi-arrow-u-left-top</v-icon></v-btn
                >
              </v-card>
            </v-row>

            <div>
              <v-card class="" elevation="2">
                <v-card-text> </v-card-text>
                <v-card-actions>
                  <v-btn class="info mx-1 text-capitalize">
                    Email Contract
                  </v-btn>
                  <v-btn class="info mx-1 text-capitalize">
                    Print Contract
                  </v-btn>
                  <v-btn class="success mx-1 text-capitalize">
                    Contract Experience</v-btn
                  >
                </v-card-actions>
              </v-card>
            </div>
            <!-- END contact approval  -->
            <v-divider class="my-3"></v-divider>

            <v-btn class="success text-capitalize" @click="e6 = 2">
              <v-icon>mdi-arrow-up-circle-outline</v-icon> Previous
            </v-btn>
            <v-btn
              class="success text-capitalize ml-1 float-right"
              @click="e6 = 4"
            >
              Next <v-icon>mdi-arrow-down-circle-outline</v-icon></v-btn
            >
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-divider class="mx-4" v-if="supplier.company"></v-divider>
      <v-card-actions>
        <!-- <v-btn color="deep-purple lighten-2" text @click="reserve">
          Previous
        </v-btn>
        <v-btn color="deep-purple lighten-2" text @click="reserve">
          Next
        </v-btn> -->
      </v-card-actions>
    </v-card>

    <div class="text-center">
      <v-dialog v-model="dialogSendToSuppliers" width="600">
        <v-card>
          <v-card-title class="headline">
            Request Quotation from suppliers
          </v-card-title>
          <v-divider></v-divider>
          <v-col cols="6" md="6">
            <v-btn
              color="primary "
              class="mx-1"
              fab
              x-small
              dark
              @click="generateReport"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-col>

          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-col cols="12" md="12" class="mb-n3">
              <v-select
                v-model="editedItem.supplier_group_id"
                :items="supplier_groups"
                label="Select supplier group"
                item-text="title"
                item-value="id"
                color="grey"
                dense
                outlined
                class="d-flex align-end"
                :rules="selectRules"
              ></v-select>
            </v-col>
          </v-form>
          <div id="printable_content">
            <v-card-text>
              <h3>{{ editedItem.title }}</h3>
              <v-divider></v-divider>
              <div
                class="pa-2 bt-3 outlined"
                v-html="editedItem.description"
              ></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-col cols="12" sm="12" md="12" class="my-2">
              <label>Requisition Items</label>

              <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(requisition_item, i) in requisition_items"
                        :key="i"
                      >
                        <td data-label="No.">{{ i + 1 }}</td>
                        <td>
                          <v-text-field
                            v-model="requisition_item.name"
                            label="Name"
                            :rules="rules.items"
                            color="grey"
                            dense
                            readonly
                            outlined
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Name
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </td>

                        <td>
                          <v-text-field
                            v-model="requisition_item.quantity"
                            label="Quantity"
                            :rules="rules.items"
                            color="grey"
                            dense
                            readonly
                            outlined
                            type="number"
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Quantity
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              dark
              @click="requestQuotation"
              >Request Quotations</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import QuotationRequestService from "../../services/quotation_request.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WorkflowService from "../../services/workflow.service";
import SupplierCategoryService from "../../services/supplier_category.service";
import MainStepper from "../../components/MainStepper.vue";
import RequisitionService from "../../services/requisition.service";
import ContractService from "../../services/contract.service";
import ContractTypeService from "../../services/contract_type.service";
import ContractTemplateService from "../../services/contract_template.service";

import vueSignature from "vue-signature";

import Editor from "@tinymce/tinymce-vue";
import moment from "moment";

import VueHtml2pdf from "vue-html2pdf";

const API_KEY = "z2fvgb12fid20qablvolrzqahufdng4v0sjz28p7hxhp9w9u";
const config = {
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste",
  ],
  toolbar:
    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
  height: 400,
};

let content = `
<h1>Hello from Tiny!</h1>
<p>This is Editor Content!</p>
`;

export default {
  components: {
    Editor,
    VueHtml2pdf,
    MainStepper,
    vueSignature,
  },
  data: (vm) => ({
    e6: 1,
    name: "Authority Approvals",
    workflows: [],
    items: [],
    supplier_groups: [],
    selectRules: [(v) => !!v || "Select is required"],
    dialog_view: false,
    dialogSendToSuppliers: false,
    editor: ClassicEditor,
    contract_types: [],
    selection: 1,
    timer: "",

    // Start pad
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
    },
    disabled: false,
    // End pad

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,
    currencies: ["KES", "UGX", "USD"],
    departments: [
      "Finance",
      "HR",
      "ICT",
      "Marketing",
      "Procurement",
      "Production",
      "Sales",
    ],
    contract_areas: [
      "Artificial Intelligence",
      "Employee Training & Development",
      "ICT Business Intelligence",
      "Sales & Marketing",
      "Research",
    ],
    reminder_types: [
      "One Week",
      "One Month",
      "One year",
      "Three Months",
      "Six Months",
    ],
    tenure_types: ["Renewable", "Expiring", "Open"],
    contract_options: [
      {
        id: "new",
        name: "Draft A New Contract",
      },
      {
        id: "upload",
        name: "Upload Existing Contract",
      },
      {
        id: "copy",
        name: "Copy Existing Contract",
      },
      {
        id: "template",
        name: "Copy Contract Template",
      },
      {
        id: "renew",
        name: "Renew Expiring Contract",
      },
    ],

    contract_templates: [],

    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    editorConfig2: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      readOnly: true,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    contract_items: [],
    requisition_items: [],
    search: "",
    editedIndex: -1,
    editedItem: {
      id: "",
      reference: "PPA" + moment().format("yyMMDDhhmmss"),
      title: "",
      amount: 0,
      description: "",
    },
    defaultItem: {
      id: "",
      reference: "PPA" + moment().format("yyMMDDhhmmss"),
      title: "",
      amount: 0,
      description: "",
    },
    remember: 0,
    href: "workflows",
    timeout: 2000,
    dialog: false,
    valid: true,
    valid1: true,
    API_KEY,
    config,
    content,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      items: [(v) => !!v || "Field is required"],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Workflow",
        value: "workflow",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Attached Document",
        value: "attachment",
      },
      {
        text: "Actions",
        align: "right",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    requisition() {
      return localStorage.getItem("requisition")
        ? JSON.parse(localStorage.getItem("requisition"))
        : [];
    },
    user() {
      return this.$store.state.auth.user;
    },
    supplier() {
      return localStorage.getItem("supplier")
        ? JSON.parse(localStorage.getItem("supplier"))
        : [];
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      console.log(val);
    },
  },

  created() {
    this.index();
    this.getContractTypes();
    this.getContractTemplates();
    this.getAllWorkflows();
    this.getSupplierCategories();
    this.editedItem.title = this.requisition.title;
  },
  methods: {
    // START vue pad
    saveCompanySignature() {
      var _this = this;
      var png = _this.$refs.signature.save();
      var png1 = _this.$refs.supplier_signature.save();
      var jpeg = _this.$refs.signature.save("image/jpeg");
      var svg = _this.$refs.signature.save("image/svg+xml");
      this.editedItem.company_signature  = png
      this.editedItem.supplier_signature  = png1
      console.log(png);
      console.log(jpeg);
      console.log(svg);
    },
    saveSupplierSignature() {
      var _this = this;
      var png = _this.$refs.signature.save();
      var png1 = _this.$refs.supplier_signature.save();
      var jpeg = _this.$refs.signature.save("image/jpeg");
      var svg = _this.$refs.signature.save("image/svg+xml");
      this.editedItem.company_signature  = png
      this.editedItem.supplier_signature  = png1
      console.log(png);
      console.log(jpeg);
      console.log(svg);
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },
    addWaterMark() {
      var _this = this;
      _this.$refs.signature.addWaterMark({
        text: "mark text", // watermark text, > default ''
        font: "20px Arial", // mark font, > default '20px sans-serif'
        style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: "red", // fillcolor, > default '#333'
        strokeStyle: "blue", // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200, // stroke positionY, > default 40
      });
    },
    fromDataURL(url) {
      var _this = this;
      console.log(url);
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
    handleDisabled() {
      var _this = this;
      _this.disabled = !_this.disabled;
    },
    // END vue pad

    setTinyContent: function () {
      Editor.activeEditor.setContent(content);
      console.log(content);
    },
    templateSelected(e) {
      this.loading = true;
      let selectedCategory = this.contract_templates.find((i) => i.id == e);
      this.editedItem.content = selectedCategory.content;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    validate() {
      this.$refs.form_contract_initiation.validate();
    },
    reset() {
      this.$refs.form_contract_initiation.reset();
    },
    resetValidation() {
      this.$refs.form_contract_initiation.resetValidation();
    },

    saveStepOne() {
      this.editedItem.amount = this.requisition.amount;
      this.editedItem.expiry_date = this.dateFormatted;
      this.editedItem.party_one_id = this.user.company.id;
      this.editedItem.requisition_id = this.requisition.id;
      this.editedItem.party_two_id = this.supplier.company.id;
      this.editedItem.currency_id = this.requisition.currency_id;
      // samdoh
      console.group("Post Data");
      console.log(this.editedItem);
      this.loading = true;
      this.$refs.form_contract_initiation.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          ContractService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.e6 = 2;
                this.editedIndex = 1;
                this.editedItem.id = response.data.data.id;
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
          );
        } else {
          ContractService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.e6 = 2;
                this.editedIndex = 1;
                this.loading = false;
                this.editedItem.id = response.data.data.id;
                console.group("Update item");
                console.group(response.data);
                console.group("Update new item");
                console.group(this.editedItem);
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Oops, contract name is already taken!"
                );
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
      }
    },

    saveStepTwo() {
      ContractService.update(this.editedItem).then(
        (response) => {
          console.group("editItem");
          console.log(this.editItem);
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.loading = false;
            this.e6 = 3;
            this.close();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.index();
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
          this.loading = false;
        }
      );

      this.loading = false;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    gotToAllContracts() {
      this.$router.push("/contracts");
    },
    gotToRequisition() {
      this.$router.push("/requisition");
    },
    gotToMyApprovals() {
      this.$router.push("/requisition-approval");
    },
    goToNeedIdentification() {
      this.$router.push("/need-identification");
    },
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    executeApproved(item) {
      // samdoh
      this.$router.push("/contract-initiation/" + item.id);
      console.log(item);
    },
    displayType(type) {
      if (type == 1) {
        return "<v-chip color='orange'>Capex</v-chip>";
      } else {
        return "<v-chip color='primary'>Opex</v-chip>";
      }
    },

    toMoneyFormat(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getContractTemplates() {
      return ContractTemplateService.index().then(
        (response) => {
          if (response.status == 200) {
            this.contract_templates = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getContractTypes() {
      return ContractTypeService.index().then(
        (response) => {
          if (response.status == 200) {
            this.contract_types = response.data.data;
            this.loading = false;
          } else {
            this.contract_types = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.contract_types = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getSupplierCategories() {
      return SupplierCategoryService.index().then(
        (response) => {
          if (response.status == 200) {
            this.supplier_groups = response.data.data;
            this.loading = false;
          } else {
            this.supplier_groups = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.supplier_groups = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    addNewItem() {
      this.requisition_items.push({ name: "", quantity: "" });
    },
    addNewEditedItem() {
      this.requisition_items.push({ name: "", quantity: "", edited: 1 });
    },
    generateReport() {
      var printable_content = document.getElementById("printable_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },
    getAllWorkflows() {
      return WorkflowService.index().then(
        (response) => {
          if (response.status == 200) {
            this.workflows = response.data.data;
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.roles = [];
          console.log(error);
        }
      );
    },
    trimText(text, length) {
      text.substring(0, length);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog_view = true;
    },
    sendItemToSupplier(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialogSendToSuppliers = true;
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    index() {
      return RequisitionService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },
    sendToEboard(item) {
      item.approval_name = item.title;
      item.applicant_name = this.user.name;
      const index = this.items.indexOf(item);
      var sendConfirm = confirm("Confirm sending item for eBoard approval?");
      if (sendConfirm) {
        RequisitionService.sendToEboard(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
              this.index();
            } else {
              this.$store.dispatch("alert/error", "Error sending approval");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser) {
        RequisitionService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
            } else {
              this.$store.dispatch("alert/error", "Error deleting document");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },
    deleteNewRequisitionItem(item) {
      const index = this.requisition_items.indexOf(item);
      var deleteReqItem = confirm("Are you sure you want to delete this item?");
      if (deleteReqItem) {
        this.requisition_items.splice(index, 1);
      }
    },
    deleteRequisitionItem(item) {
      const index = this.requisition_items.indexOf(item);
      var deleteReqItem = null;
      if (item.edited == 1) {
        deleteReqItem = confirm("Are you sure you want to delete this item?");
        if (deleteReqItem) {
          this.requisition_items.splice(index, 1);
        }
      } else {
        deleteReqItem = confirm("Are you sure you want to delete this item?");
        if (deleteReqItem) {
          RequisitionService.deleteItem(item).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.requisition_items.splice(index, 1);
              } else {
                this.$store.dispatch("alert/error", "Error deleting document");
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 500) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 502) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 401) {
                this.handleLogout();
              } else {
                this.$store.dispatch("alert/error", error.response.statusText);
              }
            }
          );
        }
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
    },

    requestQuotation() {
      this.loading = true;
      this.$refs.form1.validate();
      if (this.valid) {
        this.editedItem.requisition_id = this.editedItem.id;
        this.editedItem.user_id = this.user.id;
        QuotationRequestService.store(this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch(
                "alert/success",
                "Request sent successfully"
              );
              this.loading = false;
              this.dialogSendToSuppliers = false;
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.dialogSendToSuppliers = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
