// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class ContractTypeService {

    index() {
        return axios.get(API_URL + 'contract-type', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'contract-type/' + id, { headers: authHeader() })
    }
    store(user) {
        return axios.post(API_URL + 'contract-type', user, { headers: authHeader() })
    }
    update(user) {
        return axios.put(API_URL + 'contract-type/' + user.id, user, { headers: authHeader() })
    }
    delete(user) {
        return axios.delete(API_URL + 'contract-type/' + user.id, { headers: authHeader() })
    }

}

export default new ContractTypeService()